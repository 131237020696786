import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Actions } from './core/Actions'
import { Link } from '../types/vmdata'
import { useSelector } from 'react-redux'
import { colorPalette } from '../styleVariables'

interface Column {
  id: 'label' | 'url' | 'actions'
  label: string
  minWidth?: number
}

const columns: Column[] = [
  { id: 'label', label: 'Label', minWidth: 150 },
  { id: 'url', label: 'URL', minWidth: 250 },
  { id: 'actions', label: 'Delete', minWidth: 30 },
]

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '62vh',
    maxHeight: '62vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
  },
  rowHead: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  rowBody: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    borderRadius: '0px 0px 2px 2px',
  },
  columnHeadCell: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  columnRowCell: {
    fontSize: '1.5rem',
    padding: '0.65rem 1.35rem',
  },
  img: {
    width: '4.55rem',
  },
  emptyMessageWrapper: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exmptyMessage: {
    fontSize: '2rem',
    color: colorPalette.greyDark,
  },
  animation: {
    animation: 'target-fade 2s ease-in',
  },
})

const EmptyMessage: React.FC = () => {
  const styles = useStyles()

  return (
    <Box className={styles.emptyMessageWrapper}>
      <Typography className={styles.exmptyMessage}>No links added</Typography>
    </Box>
  )
}

interface LinkTableProps {
  onRemove: (link: Link) => void
}

export const LinksTable: React.FC<LinkTableProps> = ({ onRemove }) => {
  const styles = useStyles()
  const links = useSelector((state) => state.vmData.links)

  return (
    <Paper className={styles.root}>
      <div className={styles.container}>
        <Table stickyHeader>
          <TableHead className={styles.rowHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={styles.columnHeadCell}
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {links.map((row: Link) => (
              <TableRow
                role="checkbox"
                tabIndex={-1}
                key={row.url}
                className={styles.rowBody}
              >
                <TableCell className={styles.columnRowCell}>
                  {row.label}
                </TableCell>
                <TableCell className={styles.columnRowCell}>
                  {row.url}
                </TableCell>
                <TableCell className={styles.columnRowCell}>
                  <Actions remove={() => onRemove(row)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!links.length && <EmptyMessage />}
      </div>
    </Paper>
  )
}
