import React, { useState } from 'react'
import { Dialog, Box, TextField, Button, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { addLink } from '../features/links/linkSlice'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '3rem',
      position: 'relative',
      minWidth: '50vw',
    },
    title: {
      fontSize: '2.35rem',
      fontWeight: 700,
      marginBottom: '2rem',
    },
    urlInputWrapper: {
      display: 'flex',
      marginTop: '3rem',
      marginBottom: '3rem',
      flexDirection: 'column',
    },
    labelInput: {
      minWidth: '20rem',
    },
    urlInput: {
      minWidth: '20rem',
    },
    btnsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnText: {
      fontSize: '1.35rem',
    },
  })
)

interface AddLinkModalProps {
  isOpen: boolean
  onClose: () => void
}

export const AddLinkModal: React.FC<AddLinkModalProps> = ({
  isOpen,
  onClose,
}) => {
  const styles = useStyles()
  const [label, setLabel] = useState('')
  const [url, setUrl] = useState('')
  const dispatch = useDispatch()

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e.target.value
    setLabel(label)
  }
  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value
    setUrl(url)
  }

  const onHandleAdd = () => {
    dispatch(addLink({ label, url }))
    setLabel('')
    setUrl('')
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <Box className={styles.content}>
        <>
          <Typography className={styles.title}>Add link properties</Typography>
          <TextField
            className={styles.labelInput}
            id="label"
            label="Label"
            onChange={handleChangeLabel}
            value={label}
            inputProps={{ maxlength: '30' }}
            error={label.length === 30}
            helperText={
              label.length === 30 && (
                <span>
                  The <b>Label</b> must have a length of maximum 30 characters
                  (spaces included)
                </span>
              )
            }
          />

          <Box className={styles.urlInputWrapper} style={{ display: 'flex' }}>
            <TextField
              style={{ flexGrow: 1 }}
              className={styles.urlInput}
              id="url"
              label="URL"
              onChange={handleChangeUrl}
              value={url}
            />
          </Box>
        </>

        <Box className={styles.btnsWrapper}>
          <Button onClick={onClose} style={{ marginRight: '2rem' }}>
            <Typography className={styles.btnText}>cancel</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!label || !url}
            onClick={onHandleAdd}
          >
            <Typography className={styles.btnText}>add link</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
