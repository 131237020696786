import { combineReducers } from '@reduxjs/toolkit'
import products from '../features/products/productSlice'
import store from '../features/store/storeSlice'
import analytics from '../features/analytics/analyticsSlice'
import style from '../features/style/styleSlice'
import expire from '../features/expiration/expirationSlice'
import name from '../features/experienceName/experienceNameSlice'
import createdBy from '../features/experienceCreatedBy/experienceCreatedBySlice'
import qrCode from '../features/qrCode/qrCodeSlice'
import listing from '../features/listing/listingSlice'
import links from '../features/links/linkSlice'
import user from '../features/user/userSlice'
import vmmvModes from '../features/visualizationModes/visualizationModesSlice'

const vmData = combineReducers({
  products,
  store,
  analytics,
  style,
  expire,
  links,
  name,
  createdBy,
  vmmvModes,
})

const rootReducer = combineReducers({
  vmData,
  qrCode,
  listing,
  user,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
