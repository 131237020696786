import React, { useCallback, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
} from '@material-ui/core'
import { TitleEdit } from './core/TitleEdit'
import { AddToPhotos } from '@material-ui/icons'
import { ListingTable } from './ListingTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteListingData,
  getListingData,
  setEditableUuid,
  setSearch,
} from '../features/listing/listingSlice'
import { VMListData } from '../types/vmlistingdata'
import VTOCatalogServices from '../services/vtoCatalogServices'
import { useAsync } from '../hooks/useAsync'
import { replaceProducts } from '../features/products/productSlice'
import { setStoreData } from '../features/store/storeSlice'
import { setStyleData } from '../features/style/styleSlice'
import { setAnalyticsData } from '../features/analytics/analyticsSlice'
import { setExpirationData } from '../features/expiration/expirationSlice'
import { setExperienceName } from '../features/experienceName/experienceNameSlice'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app/routers'
import { getHashFromUrl } from '../libs/utils'
import { PreviewModal } from './PreviewModal'
import { replaceLinks } from '../features/links/linkSlice'
import { VMDataExperience } from '../types/vmdata'
import { setVmmvModes } from '../features/visualizationModes/visualizationModesSlice'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'left',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '3rem',
    },
    searchInputTop: {
      marginRight: '2rem',
      minWidth: '30rem',
    },
    tableSearchWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    searchInput: {
      marginRight: '2rem',
      minWidth: '30rem',
      marginBottom: '1.75rem',
    },
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
  })
)

export const Listing: React.FC = () => {
  const styles = useStyles()
  const [url, setUrl] = useState('')
  const [vmDataPreview, setVmDataPreview] = useState<VMListData | null>(null)
  const [isPreviewOpen, togglePreview] = useState(false)
  const dispatch = useDispatch()
  const search = useSelector((state) => state.listing.search)
  const history = useHistory()
  const hash = getHashFromUrl(url)

  const { run, data, error, setError, loading } = useAsync(
    VTOCatalogServices.getVMDataByUUID
  )

  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value
    setUrl(url)
  }
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    dispatch(setSearch(value))
  }

  useEffect(() => {
    const onUnauthorized = () => {
      history.push('/logout')
    }

    dispatch(getListingData(onUnauthorized))
  }, [dispatch, history])

  useEffect(() => {
    if (data) {
      dispatchData(data)
    }
  }, [data, dispatch, history])

  const dispatchData = (data?: VMDataExperience) => {
    if (data) {
      dispatch(replaceLinks(data.links))
      dispatch(replaceProducts(data.products))
      dispatch(setStoreData(data.store))
      dispatch(setStyleData(data.style))
      dispatch(setAnalyticsData(data.analytics))
      dispatch(setExpirationData(data.expire))
      dispatch(setExperienceName(data.name))

      const {
        isTryOnEnabled = false,
        isPictureModeEnabled = false,
        isVideoModeEnabled = false,
      } = data.vmmvModes || {}

      dispatch(
        setVmmvModes({
          isTryOnEnabled,
          isPictureModeEnabled,
          isVideoModeEnabled,
        })
      )

      history.push(AppRoutes.edit())
    }
  }

  const handleDelete = useCallback(
    async (data: VMListData) => {
      const onUnauthorized = () => {
        history.push('/logout')
      }

      await dispatch(deleteListingData(data))
      dispatch(getListingData(onUnauthorized))
    },
    [dispatch, history]
  )

  const handleCreateOrEdit = useCallback(
    (hash: string | null, isEdit: boolean, VMData?: VMDataExperience) => {
      if (isEdit) {
        hash && dispatch(setEditableUuid(hash))
        if (VMData) {
          dispatchData(VMData)
          return
        }
      }
      hash && run(hash)
    },
    [run, dispatch]
  )

  const handleToggle = () => {
    togglePreview((c) => !c)
  }

  const handlePreview = (data: VMListData) => {
    setVmDataPreview(data)
    handleToggle()
  }

  return (
    <>
      <Box>
        <TitleEdit title="Load Previous Experience" className={styles.title} />
        <Box className={styles.searchWrapper}>
          <TextField
            error={!hash && !!url.length}
            id="url"
            className={styles.searchInputTop}
            label="Experience URL"
            helperText={!hash && !!url.length && 'Incorrect url'}
            placeholder="https://virtual-mirror.com?hash=fe5b767y8504uy0303y"
            onChange={handleChangeUrl}
            value={url}
          />
          <Button
            color="primary"
            variant="contained"
            disabled={!hash || !url.length}
            onClick={() => handleCreateOrEdit(hash, false)}
          >
            <AddToPhotos />
            <Typography>create from previous experience</Typography>
          </Button>
        </Box>
      </Box>

      <Box>
        <Box className={styles.tableSearchWrapper}>
          <TitleEdit title="List" className={styles.title} />
          <TextField
            className={styles.searchInput}
            value={search}
            label="Search"
            onChange={handleSearch}
          />
        </Box>
        <ListingTable
          onCreateOrEdit={handleCreateOrEdit}
          onRemove={handleDelete}
          onPreview={handlePreview}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        message="Error: VM Data not found by hash"
        autoHideDuration={6000}
        onClose={() => setError(false)}
      />
      {loading && (
        <Box className={styles.loaderWrapper}>
          <CircularProgress />
        </Box>
      )}
      <PreviewModal
        isOpen={isPreviewOpen}
        onClose={handleToggle}
        vmDataPreview={vmDataPreview}
      />
    </>
  )
}
