import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'
import { TitleEdit } from '../components/core/TitleEdit'
import { Add } from '@material-ui/icons'
import { Link } from '../types/vmdata'
import { useDispatch, useSelector } from 'react-redux'
import { WizardStepperFooter } from '../components/WizardStepperFooter'
import { LinksTable } from '../components/LinksTable'
import { AddLinkModal } from '../components/AddLinkModal'
import { removeLink } from '../features/links/linkSlice'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flexGrow: 1,
    },
    title: {
      marginBottom: 0,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: '2rem',
    },
    btn: {
      padding: '0.5rem 2rem',
      borderRadius: '3rem',
    },
    btnQr: {
      padding: '0.5rem 2rem',
      borderRadius: '3rem',
      marginRight: '1rem',
    },
    btnText: {
      fontSize: '1.35rem',
      fontWeight: 500,
    },
    tableWrapper: {
      marginBottom: '2rem',
    },
    linksTableNoteContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

export const EditLinksPage: React.FC = () => {
  const styles = useStyles()
  const [isOpen, toggleOpen] = useState(false)
  const links = useSelector((state) => state.vmData.links)
  const dispatch = useDispatch()

  const handleToggle = () => {
    toggleOpen((c) => !c)
  }

  const onRemove = (link: Link) => {
    dispatch(removeLink(link))
  }

  return (
    <Box className={styles.content}>
      <TitleEdit className={styles.title} title="Links" />
      <Box className={styles.header}>
        <Box className={styles.linksTableNoteContainer}>
          <Typography>
            Here you can add a Call to Action and a Link tab to the experience
          </Typography>
          <Typography>
            Please note that you can only add up to 2 links to external websites
          </Typography>
        </Box>
        {links.length < 2 ? (
          <Button
            color="primary"
            variant="contained"
            onClick={handleToggle}
            className={styles.btn}
          >
            <Add />
            <Typography className={styles.btnText}>add link</Typography>
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={handleToggle}
            className={styles.btn}
            disabled
          >
            <Add />
            <Typography className={styles.btnText}>add link</Typography>
          </Button>
        )}
      </Box>
      <Box className={styles.tableWrapper}>
        <LinksTable onRemove={onRemove} />
      </Box>
      <AddLinkModal isOpen={isOpen} onClose={handleToggle} />
      <WizardStepperFooter isValid={true} />
    </Box>
  )
}
